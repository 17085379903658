import { formatCurrency } from '@smarttransit/common'

function _formatAsCents (val) {
  return Math.round(parseFloat(val || '0') * 100)
}

export default {
  name: 'currency-field',
  props: {
    childProps: Object,
    value: [String, Number], // value must be in cents
    delay: Boolean
  },
  data () {
    return {
      processedValue: null
    }
  },
  mounted () {
    if (this.$props.value) {
      this.processedValue = formatCurrency(this.$props.value)
    }
  },
  watch: {
    processedValue: function (newVal) {
      newVal = (newVal ? newVal + '' : '').trim()
      this.$emit('input', newVal ? _formatAsCents(newVal) : null)
    },
    value (val) {
      clearTimeout(this.timeoutHandle)

      this.timeoutHandle = setTimeout(() => {
        this.processedValue = val === null || val === undefined ? '' : formatCurrency(val, null, '0.00')
      }, 500)
    }
  },
  methods: {
    emitEvent (event) {
      this.$emit(event, this.processedValue ? _formatAsCents(this.processedValue) : null)
    },
    onCurrencyChange (newVal) {
      newVal = (newVal ? newVal + '' : '').trim()

      if (newVal) {
        this.processedValue = formatCurrency(newVal, null, '0.00')
      }
    }
  },
  beforeDestroy () {
    clearTimeout(this.timeoutHandle)
  }
}
